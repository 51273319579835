import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Power Up Your Command Line II",
  "date": "2019-01-04T18:00:00.000Z",
  "layout": "post",
  "draft": false,
  "path": "/posts/more-tools",
  "category": "Software",
  "tags": ["productivity", "command line", "tips"],
  "description": "Showcasing more of my favourite lesser-known command line tools."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is part II of the series “`}<em parentName="p">{`Power Up Your Command Line`}</em>{`”, which showcases excellent non-standard tools that will make using the command line easier and more enjoyable.`}</p>
    <h2><inlineCode parentName="h2">{`peco`}</inlineCode>{`, interactively filter anything`}</h2>
    <p>{`Pipe the output of any command into `}<inlineCode parentName="p">{`peco`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/peco/peco"
      }}>{`GitHub`}</a>{`), and you’ll be able to interactively filter it to find the information you need. Think of it like an interactive form of `}<inlineCode parentName="p">{`grep`}</inlineCode>{`, where the real-time updating of results as you type allows for a more exploratory search process.`}</p>
    <p><img parentName="p" {...{
        "src": "/e31d5338bae787510ce8e39ad394bdb0/peco-processes.gif",
        "alt": "peco-processes"
      }}></img></p>
    <p>{`You can move up and down through the search results, and select them with enter. When you press enter, the command outputs the result.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`peco`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`On macOS with Homebrew: `}<inlineCode parentName="li">{`brew install peco`}</inlineCode></li>
    </ul>
    <h2><inlineCode parentName="h2">{`hexyl`}</inlineCode>{`, a hex viewer`}</h2>
    <p>{`If you want to examine a binary file, you typically do so by looking at the hexadecimal representation of the file. `}<inlineCode parentName="p">{`hexyl`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/sharkdp/hexyl"
      }}>{`GitHub`}</a>{`) is a command line tool for doing just that.`}</p>
    <p><img parentName="p" {...{
        "src": "/95d319279084793ad8bba3e3fd6acf2d/hexyl-no-less.gif",
        "alt": "hexyl"
      }}></img></p>
    <p>{`The view is split into three columns:`}</p>
    <ol>
      <li parentName="ol">{`An `}<em parentName="li">{`offset`}</em>{` column, which tells you how many bytes into the file you are.`}</li>
      <li parentName="ol">{`A  `}<em parentName="li">{`hex`}</em>{` column (which itself has a divider down the middle - not visible in the example gifs), which contains the hexadecimal representation of the file.`}</li>
      <li parentName="ol">{`A `}<em parentName="li">{`representation`}</em>{` column (also split in two, and not shown in the example gifs), which attempts to show the file as text.`}</li>
    </ol>
    <p>{`Each byte in `}<inlineCode parentName="p">{`hexyl`}</inlineCode>{` is colour-coded depending on the type of byte it is (NULL, ASCII, non-ASCII, etc.) which really helps with readability.`}</p>
    <p><em parentName="p">{`Bonus tip`}</em>{`: If viewing binary files or large text files, the output will often be too large for your screen, so you can pipe the output from `}<inlineCode parentName="p">{`hexyl`}</inlineCode>{` into `}<inlineCode parentName="p">{`bat`}</inlineCode>{` or `}<inlineCode parentName="p">{`less`}</inlineCode>{` to give you the ability to paginate through it! You’ll need to use the `}<inlineCode parentName="p">{`--raw-control-chars`}</inlineCode>{`/`}<inlineCode parentName="p">{`-r`}</inlineCode>{` flag for the output to be coloured correctly if you use `}<inlineCode parentName="p">{`less`}</inlineCode>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "/583b828440ed738443efc8e63fe5bda3/hexyl-with-less.gif",
        "alt": "hexyl-less"
      }}></img></p>
    <p><inlineCode parentName="p">{`hexyl`}</inlineCode>{` is written in Rust, and is developed by `}<a parentName="p" {...{
        "href": "https://github.com/sharkdp"
      }}>{`David Peter`}</a>{` who also wrote `}<inlineCode parentName="p">{`bat`}</inlineCode>{`, `}<inlineCode parentName="p">{`fd`}</inlineCode>{`, and `}<inlineCode parentName="p">{`hyperfine`}</inlineCode>{`, all of which I mentioned in part one of this series.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`hexyl`}</inlineCode></h3>
    <p>{`Installing `}<inlineCode parentName="p">{`hexyl`}</inlineCode>{` requires a few steps on macOS:`}</p>
    <ol>
      <li parentName="ol">{`Download the binary from GitHub: `}<inlineCode parentName="li">{`curl -L -o hexyl.tar.gz https://github.com/sharkdp/hexyl/releases/download/v0.3.0/hexyl-v0.3.0-x86_64-apple-darwin.tar.gz`}</inlineCode></li>
      <li parentName="ol">{`Extract the tarball: `}<inlineCode parentName="li">{`tar -xf hexyl.tar.gz`}</inlineCode></li>
      <li parentName="ol">{`Add it to your `}<inlineCode parentName="li">{`PATH`}</inlineCode>{`: `}<inlineCode parentName="li">{`mv hexyl-v0.3.0-x86_64-apple-darwin/hexyl /usr/local/bin`}</inlineCode></li>
      <li parentName="ol">{`Make executable: `}<inlineCode parentName="li">{`chmod +x /usr/local/bin/hexyl`}</inlineCode>{` `}</li>
    </ol>
    <h2><inlineCode parentName="h2">{`pomo`}</inlineCode>{`, a pomodoro timer`}</h2>
    <p>{`The pomodoro technique is a great way to boost your productivity. If you haven’t heard of it before, it works like this:`}</p>
    <ol>
      <li parentName="ol">{`For `}<em parentName="li">{`25 minutes`}</em>{`, you `}<em parentName="li">{`work`}</em>{` and allow for no distractions.`}</li>
      <li parentName="ol">{`For `}<em parentName="li">{`5 minutes`}</em>{`, you `}<em parentName="li">{`relax`}</em>{` and do whatever you want (as long as it isn’t work 😁).`}</li>
      <li parentName="ol">{`Repeat the steps above 4 times (you can adjust this number to suit yourself), then rest for  `}<em parentName="li">{`15 minutes`}</em></li>
    </ol>
    <p>{`If you follow the plan above, the theory is that you’ll get considerably more done in considerably less time. It might not work for everyone, but I can personally vouch for it’s effectiveness!`}</p>
    <p><img parentName="p" {...{
        "src": "/2a74022e8fdf4e05976d9f04ef381f4c/pomo.gif",
        "alt": "pomo"
      }}></img></p>
    <p><inlineCode parentName="p">{`pomo`}</inlineCode>{`  (`}<a parentName="p" {...{
        "href": "https://github.com/kevinschoon/pomo"
      }}>{`GitHub`}</a>{`) is a simple CLI tool to help you manage your time through the pomodoro technique.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`pomo`}</inlineCode></h3>
    <p>{`There’s a few steps to follow to install this one (on macOS):`}</p>
    <ol>
      <li parentName="ol">{`Download the binary from GitHub: `}<inlineCode parentName="li">{`curl -L -o pomo https://github.com/kevinschoon/pomo/releases/download/0.6.0/pomo-0.6.0-darwin-amd64`}</inlineCode></li>
      <li parentName="ol">{`Grant permissions: `}<inlineCode parentName="li">{`chmod +x pomo`}</inlineCode></li>
      <li parentName="ol">{`Put it on your `}<inlineCode parentName="li">{`PATH`}</inlineCode>{` so you can use it from anywhere: `}<inlineCode parentName="li">{`mv pomo /usr/local/bin`}</inlineCode>{` `}</li>
      <li parentName="ol">{`Initialise the database: `}<inlineCode parentName="li">{`pomo init`}</inlineCode></li>
    </ol>
    <h2><inlineCode parentName="h2">{`ncdu`}</inlineCode>{`, analyse and free disk space`}</h2>
    <p>{`If you’ve got a lot of projects on your computer and haven’t cleaned up your disk recently, you’ll almost certainly find a rogue folder that is hogging disk space (I’ve found some `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{` folders in older projects to be particularly susceptible). `}<inlineCode parentName="p">{`[ncdu](https://dev.yorhel.nl/ncdu)`}</inlineCode>{` is my favourite tool for fixing this. In fact, in the process of creating the demonstration for `}<inlineCode parentName="p">{`ncdu`}</inlineCode>{` below, I freed up over 10GiB of disk space!`}</p>
    <p><img parentName="p" {...{
        "src": "/d9e7172fd2a64a0ca6205f0de2f17b7a/ncdu.gif",
        "alt": "ncdu"
      }}></img></p>
    <p>{`To use it, run `}<inlineCode parentName="p">{`ncdu`}</inlineCode>{`. It’ll scan all subdirectories from the directory you ran it in, so if you run it in your home directory, it might take some time.`}</p>
    <p>{`You can use its ncurses interface to browse through your filesystem using the arrow keys, or the navigation keys from `}<inlineCode parentName="p">{`vim`}</inlineCode>{`, if that’s your thing.`}</p>
    <h3>{`Installing `}<inlineCode parentName="h3">{`ncdu`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`On macOS with `}<a parentName="li" {...{
          "href": "https://brew.sh"
        }}>{`Homebrew`}</a>{`: `}<inlineCode parentName="li">{`brew install ncdu`}</inlineCode></li>
    </ul>
    <h2>{`HTTPie, a modern replacement for `}<inlineCode parentName="h2">{`curl`}</inlineCode></h2>
    <p><a parentName="p" {...{
        "href": "https://httpie.org"
      }}>{`HTTPie`}</a>{` (`}<inlineCode parentName="p">{`http`}</inlineCode>{`) is a simpler (meaning I don’t have to Google it every time, unlike with `}<inlineCode parentName="p">{`curl`}</inlineCode>{`), feature-rich and beautiful alternative to `}<inlineCode parentName="p">{`curl`}</inlineCode>{`, which will let you call APIs over HTTP from the command line. It’s the most popular utility I’ve reviewed so far, and has exceptional `}<a parentName="p" {...{
        "href": "https://httpie.org/doc"
      }}>{`documentation`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/45e43f1f800784a18fcdbf1b089b9513/httpie.gif",
        "alt": "httpie"
      }}></img></p>
    <p>{`The output from the `}<inlineCode parentName="p">{`http`}</inlineCode>{` command is what really sets it apart from cURL. JSON responses are pretty printed with syntax highlighting, making them far more readable. If you'd rather use a graphical UI for interacting with APIs, you might like Insomnia, Postman or Paw (Paw requires a license and is macOS only).`}</p>
    <h3>{`Installing HTTPie`}</h3>
    <ul>
      <li parentName="ul">{`On macOS with Homebrew: `}<inlineCode parentName="li">{`brew install httpie`}</inlineCode></li>
    </ul>
    <h2>{`Conclusion`}</h2>
    <p>{`Thanks for reading! There are a couple more tools I’d like to mention in the next post in the series, but if you have any suggestions let me know! If you’re interested in more content like this, follow me on `}<a parentName="p" {...{
        "href": "https://twitter.com/_darrenburns"
      }}>{`Twitter`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      